export const markerDisplay = {
    //create own marker icons here :
    //https://mt.google.com/vt/icon/name=icons/onion/
    // SHARED-mymaps-pin-container-bg_4x.png,icons/onion/
    // SHARED-mymaps-pin-container_4x.png,icons/onion/
    // 1899-blank-shape_pin_4x.png&highlight=ff000000,F9B517,ff000000&scale=2.0
    // ==> adjust the F9B517 for other color code
    //ECO : #14CC4D
    //MEAL : #F59100
    //GIFT : #E60030
    //ACTIV : #0070B8
    //CORONA PREMIUM : #F9B517
    getCustomMarkerIconHighlight(type: string) {
        const iconPath = require("@/assets/images/markers/product/highlight.png")
            || require("@/assets/images/markers/product/monizze.png");
        return {
            url: iconPath,
            // scaledSize: new google.maps.Size(76, 72)
            scaledSize: new google.maps.Size(51, 49)
        };
    },
    getCustomMarkerIcon(type: string) {
        const iconPath =
            // require("@/assets/images/product_types/" + type + ".svg") ||
            require("@/assets/images/markers/product/" + type + ".png") ||
            require("@/assets/images/markers/product/monizze.png");
        return {
            url: iconPath,
            scaledSize: new google.maps.Size(38, 36)
        };
    },
    getCustomMarkerIconDefault() {
        const iconPath = require("@/assets/images/markers/product/monizze.png")||
            require("@/assets/images/markers/product/highlight.png");
        return {
            url: iconPath,
            scaledSize: new google.maps.Size(38, 36)
        };
    }

}
