import { ColorInterface } from "@/Interfaces/ColorInterfaces"

export default [
  {
    "name": "emv",
    "color": "#f59100"
  },
  {
    "name": "eco",
    "color": "#7ec225"
  },
  {
    "name": "gift",
    "color": "#d70000"
  },
  {
    "name": "activ",
    "color": "#0070b8"
  },
  {
    "name": "ecv",
    "color": "#4DBFA5"
  },
  {
    "name": "ppp",
    "color": "#4DBFA5"
  }
]
