<template>
  <!-- Main container -->
  <div class="level" v-bind="$attrs">
    <slot name="level-content">
      <div class="level-left">
        <slot name="level-left" />
      </div>
      <div class="level-right">
        <slot name="level-right" />
      </div>
    </slot>
  </div>
</template>

<script>
export default {
  name: "LevelComponent",
};
</script>

<style lang="scss">
.level {
  flex: 1;
}
</style>
