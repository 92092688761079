
import {
  ref,
  Ref,
  inject,
  watch,
  onBeforeMount,
  onMounted,
  toRef,
  toRefs,
} from "vue";
import IconByImage from "@/components/elements/IconByImage.vue";
import LevelComponent from "@/components/level/LevelComponent.vue";
import LevelItemComponent from "@/components/level/LevelItemComponent.vue";
import { ENUMVoucherTypes } from "@/Interfaces/EnumVouchers";

export default {
  name: "IconsByImageCollection",
  components: { LevelItemComponent, LevelComponent, IconByImage },
  props: {
    icons: {
      type: Array,
      required: false,
    },
    isMultiple: {
      type: Boolean,
      required: false,
      default: false
    },
    lang: {
      type: String,
      required: false,
      default: "Nl"
    }
  },
  /**
   *
   * @param props
   */
  setup(props: any) {
    const langStore: any = inject("langStore");

    // Prepare a list with all the available voucher types
    const list_available: Ref<Array<ENUMVoucherTypes>> = ref([
      ENUMVoucherTypes.MEAL,
      ENUMVoucherTypes.ECO,
      ENUMVoucherTypes.GIFT,
      ENUMVoucherTypes.ACTIV,
      ENUMVoucherTypes.PPP,
    ]);

    const language: string = langStore.getters.getCurrentLang;

    const list_current: Ref<Array<ENUMVoucherTypes>> = ref([]);

    const refIcons = toRef(props, "icons");
    const vouchersStore: any = inject("vouchersStore");

    /**
     * Filter list with all the available and possible icons to the icons requested by the developer in the
     * property.
     *
     * @param icons
     */
    const filterAssignedIconsTo = (
      icons: any
    ): ENUMVoucherTypes[] => {
      if (!icons || icons.length === 0) {
        return [];
      } else {
        const selected: number = vouchersStore.getters.getSelected;
        if (props.isMultiple) {
          const output: any = ref([]);
          for (let key in list_available.value) {
            if (icons.indexOf(list_available.value[key]) > -1) {
              output.value.push(list_available.value[key]);
            }
          }
          return output.value;
        }


        return list_available.value.filter((element: ENUMVoucherTypes): any => {

          if (props.isMultiple && refIcons.value.indexOf(element) > -1) {
              return element;
          } else {
            if (vouchersStore.actions.findElement(selected).ico == element) {
              return element;
            }
          }
          // return vouchersStore.getters.getItems[selected].ico == element ? element : list_available.value;
          /* if (props.icons.includes(element)) {
            return element;
          } */
        });
      }
    };

    watch(() => refIcons.value, (n, o) => {
      list_current.value = filterAssignedIconsTo(n);
    });

    onBeforeMount(() => {
      list_current.value = filterAssignedIconsTo(refIcons.value);
    });

    onMounted(() => {
      list_current.value = filterAssignedIconsTo(refIcons.value);
    });

    return {
      list_current,
      language,
    };
  },
};
