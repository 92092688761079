
import { computed } from "vue";

export default {
  name: "IconByImage",

  props: {
    icon: {
      type: String,
      required: true,
    },
    lang: {
      type: String,
      required: false
    }
  },

  setup(props: { icon: string, lang: string }) {
    return {
      iconPath: computed(() => {
        let suffix = 'naked';
        if (props.lang) {
          suffix = props.lang;
        }


        return require("@/assets/images/product_types/products/" + props.icon + "_" + suffix + ".svg");
      }),
    };
  },
};
