import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-a2a62fb0")
const _hoisted_1 = { class: "is-monizze-svg-icon" }
const _hoisted_2 = ["src"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _renderSlot(_ctx.$slots, "default", {}, () => [
      _createElementVNode("img", {
        src: $setup.iconPath,
        alt: ""
      }, null, 8, _hoisted_2)
    ], {}, true)
  ]))
}