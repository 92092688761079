/* eslint-disable no-undef */
import { ENUMVoucherTypes } from "@/Interfaces/EnumVouchers";
import { inject, Ref, ref } from "vue";
import { AxiosResponse } from "axios";
import http from "@/services/http";
import { IPlace } from "@/Interfaces/PlacesInterfaces";
import app from "@/components/layouts/App.vue";

export function usePlaces() {
  /**
   * Invoking the place store into the composable lib
   */
  const placeStore: any = inject("placeStore");

  const lat_lng = ref();
  const getItems = () => placeStore.getters.getItems;
  const getFindings = () => placeStore.getters.getFindings;

  /**
   * Extract all the categories from the given API
   *
   * @param lang
   * @param product
   *
   */
  const fetchPlaces = async (
      category?: any,
      product_type?: any,
      shopname?: string,
      lat?: number,
      lng?: number,
      distance?: number,
      detailLevel?: string,
      page?: number,
      limit?: number,
      append?: boolean
  ): Promise<AxiosResponse<IPlace[]>> => {
    return await http
        .get("/en/api/acceptancepoint/index", { //todo: language hardcoded
          params: {
            product: product_type,
            category: category,
            shopname: shopname,
            lat: lat,
            lng: lng,
            distance: distance,
            detailLevel: detailLevel,
            page: page,
            limit: limit
          },
        })
        .then(async (value) => {
          if (append) {
            if (value.data.length > 0) {
               //console.log("Appending data to place store. (" + value.data.length + " items)")
              placeStore.actions.appendPlaces(value.data)
            } else {
               //console.log("Stopped appending data because page " + page + "didnt return results.")
            }
          } else {
            placeStore.setters.setPlaces(value.data);
          }
          return value.data;
        }, (error) => {
          return {error: error}
        });
  };

  const fetchMerchant = async (merchantId: string, product: string):Promise<any> => {
    console.log("Fetching merchant data (id: " + merchantId + ")")
    return await http.get("/en/api/acceptancepoint/address", { //todo language hardcoded
      params: {
        id: merchantId,
        product: product
      }
    }).then(async (merchantData) => {
      //console.log('recieved merchant data for ' + merchantId)
      //console.log(merchantData)
      return merchantData;
    }, (error) => {
      return {error: error};
    });
  };


  /**
   * Get lat/lng from search string via Google geocoder
   * 
   * @param address
   * @param map
   */
  const getCoordsOf = async (
    address: string,
    searchType: string,
    google: any
  ):Promise<{lat: number, lng: number}> => {
    console.log("Start getCoordsOf this address: ")
    const geocoder: any = new google.maps.Geocoder();
    console.log(address)
    const reqObj: Ref<any> = ref({"address": address});
    const resp:Ref<any> = ref({lat: 0, lng: 0});

    return await geocoder.geocode(reqObj.value, (results: any, status: any) => {
      if (status == google.maps.GeocoderStatus.OK) {
        console.log("Geocode success.")
        const refObj = results[0].geometry.location;

        resp.value = {lat: refObj.lat(), lng: refObj.lng()};

        return resp.value;
      } else {
        return resp.value;
      }
    }).catch((error: any) => {
      return resp.value;
    });
  };

  return {
    fetchPlaces,
    fetchMerchant,
    getItems,
    getFindings,
    getCoordsOf,
  };
}
