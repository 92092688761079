
export default {
  name: "LevelItemComponent",
  props: {
    is: {
      type: String,
      default: "div",
    },
  },
};
