import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconByImage = _resolveComponent("IconByImage")!
  const _component_LevelItemComponent = _resolveComponent("LevelItemComponent")!
  const _component_LevelComponent = _resolveComponent("LevelComponent")!

  return (_openBlock(), _createBlock(_component_LevelComponent, { class: "is-icon-group is-mobile" }, {
    "level-content": _withCtx(() => [
      _createElementVNode("ul", {
        class: _normalizeClass(["is-flex is-flex-direction-row", {'ml-0': $props.isMultiple}])
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.list_current, (element) => {
          return (_openBlock(), _createBlock(_component_LevelItemComponent, {
            key: 'icon_collection_key:' + element,
            is: "li",
            class: "has-text-centered"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_IconByImage, {
                icon: element,
                lang: $setup.language
              }, null, 8, ["icon", "lang"])
            ]),
            _: 2
          }, 1024))
        }), 128))
      ], 2)
    ]),
    _: 1
  }))
}