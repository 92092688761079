/* eslint-disable no-undef */
/* eslint-disable */
import {ref, inject, onBeforeMount, Ref, onMounted} from "vue";
import {MarkerClusterer} from "@googlemaps/markerclusterer";
import Marker = google.maps.Marker;
import LatLngBounds = google.maps.LatLngBounds;
import {matchedRouteKey, useRouter, useRoute} from "vue-router";
import UIColors from "@/assets/colors";
import {getMarkerClusterer} from "@/reuseables/Clustering";
import {markerDisplay} from "@/reuseables/MarkerDisplay";

export function useMapsComposable() {
    const placeStore: any = inject("placeStore");
    const userStore: any = inject("userStore");
    const vouchersStore: any = inject("vouchersStore");

    const router = useRouter();
    const route = useRoute();

    /**
     * Create a cluster with all the markers we have alreadfy defined
     *
     * @param map
     * @param markers
     * @param clusterSize
     */

    // const intiMarkerCluster = (map: google.maps.Map, markers: Array<Marker>) => {
    //     // Add a marker cluster to manage the markers.
    //     return getMarkerClusterer(map, markers);
    // };

    // /**
    //  * Reset all the icons to the originals to simulate initial state
    //  *
    //  */
    // const resetAllIcons = () => {
    //     console.log("MC : RESET");
    //     placeStore.getters.markers.map((marker: Marker) => {
    //         marker.setIcon(markerDisplay.getCustomMarkerIconDefault());
    //     });
    // };

    /**
     * Highlight Marker on hover over search result
     *
     * @param placeID: Number - id of the place to highlight
     * @param markerType: String - voucher type name
     */

    const highlightMarker = async (placeID: number, markerType: any) => {
        // console.log("MC : HIGHLIGHT")
        const marker = await placeStore.getters.markers.filter(
            (element: Marker) => element.get("id") === placeID
        )[0];

        if (marker) {
            marker.setIcon(markerDisplay.getCustomMarkerIconHighlight(markerType));

            const markerHighlight = placeStore.getters.getMarkerHighlight;
            if (markerHighlight) {
                markerHighlight.setVisible(true)
                markerHighlight.setMap(placeStore.getters.getMap);
                markerHighlight.setPosition(marker.getPosition());
            } else {
                const markerHighlight = new google.maps.Marker({
                    map: placeStore.getters.getMap,
                    position: marker.getPosition(),
                    zIndex: 1000000,
                    icon: markerDisplay.getCustomMarkerIconHighlight('')
                });
                placeStore.setters.setMarkerHighlight(markerHighlight);
            }
        }
    };

    /**
     * Focus out Marker on mouse leave search result
     *
     * @param placeID: Number - id of the place to highlight
     * @param markerType: String - voucher type name
     */
    const focusOutMarker = async (placeID: number, markerType: any) => {
        // console.log("MC : FOCUSMARKER")
        const marker = await placeStore.getters.markers.filter(
            (element: Marker) => element.get("id") === placeID
        )[0];

        if (marker) {
            marker.setIcon(markerDisplay.getCustomMarkerIcon(markerType));

            const markerHighlight = placeStore.getters.getMarkerHighlight
            if (markerHighlight) {
                markerHighlight.setMap(null)
                markerHighlight.setVisible(false)
            }
        }
    };

    /**
     * The only way to make select some place in the map
     *
     * @param placeID
     */
    const selectMarker = async (placeID: number, markerType: string) => {
        console.log("MC : SELECTMARKER");
        //console.log(placeStore.getters.markers)

        const marker = placeStore.getters.markers
            ? await placeStore.getters.markers.filter(
                (element: Marker) =>
                    element.get("id").toString() === placeID.toString()
            )[0]
            : null;
        //console.log("selected this marker:");
        //console.log(marker);
        const fmo = userStore.getters.focusedMarkerId;
        const marker_old = !fmo
            ? marker
            : placeStore.getters.markers
                ? await placeStore.getters.markers.filter(
                    (element: Marker) => element.get("id").toString() === fmo.toString()
                )[0]
                : null;

        if (marker && (marker_old || !fmo)) {
            marker_old.setIcon(markerDisplay.getCustomMarkerIcon(markerType));
            marker.setIcon(markerDisplay.getCustomMarkerIconHighlight(markerType));
            userStore.setters.setFocusedMarkerId(placeID);

            const markerSelect = placeStore.getters.getMarkerSelect
            console.log('markerSelect')
            console.log(markerSelect)

            if (markerSelect) {
                markerSelect.setMap(placeStore.getters.getMap)
                markerSelect.setPosition(marker.getPosition())
            } else {
                const markerSelect = new google.maps.Marker({
                    map: placeStore.getters.getMap,
                    position: marker.getPosition(),
                    zIndex: 1000000,
                    icon: markerDisplay.getCustomMarkerIconHighlight('')
                });
                placeStore.setters.setMarkerSelect(markerSelect);
                console.log(placeStore.getters.getMarkerSelect);
            }
            console.log('====')

            //console.log("MC PREVIOUS/CURRENT ID SELECT: ", fmo, placeID);
        }
    };

    /**
     *
     *
     * @param markersStore
     */
    const prepareMarkers = (
        rawMarkers: Array<any>,
        map: google.maps.Map,
        bounder: LatLngBounds,
        voucherType: string,
        infoWindow?: google.maps.InfoWindow
    ) => {
        /* Clear previously displayed markers*/
        // console.log("MC : PREPARE MARKERS");
        const markerColor: string = vouchersStore.actions.findElement(parseInt(voucherType))?.color
        const markerClusterer = getMarkerClusterer(map, undefined, markerColor)
        placeStore.setters.setMarkerCluster(markerClusterer)
        const currentMarkers = placeStore.getters.markers || [];
        currentMarkers.forEach((marker: Marker, index: number) => {
            marker.setMap(null);
        });
        const markerType: string = vouchersStore.actions.findElement(parseInt(voucherType))?.ico
        const a = rawMarkers.map((element: any, i: number) => {
            const label: string = ++i + "";
            const rqParam: any = ref(undefined);
            const colorObj: any = UIColors.find(
                (colorObj: any, colorObjIndex: number) => {
                    return colorObjIndex === parseInt(voucherType) - 1;
                }
            );
            let position: any;

            if (typeof element.position.lat == 'function') {
                position = {
                    lat: element.position.lat(),
                    lng: element.position.lng(),
                };
            } else {
                position = {
                    lat: element.position.lat,
                    lng: element.position.lng,
                };
            }

            const marker = new google.maps.Marker({
                icon: markerDisplay.getCustomMarkerIcon(markerType),
                position: position,
                map: null,
            });
            //TODO to decrease impact toggle optimized move to selected markers only

            //optimized true prevent markers from being updated correctly
            marker.setOptions({optimized: false});

            /**
             * markers can only be keyboard focusable when they have click listeners
             */
            marker.addListener("click", async () => {
                /*
                console.log("LABEL : " + label);
                console.log("ELEMENT : " + element.id);
                */
                if (route.name == "Search") {
                    rqParam.value = true;
                }

                router.push({
                    name: "MerchantDetails",
                    params: {
                        merchant_id: element.id,
                    },
                    query: {
                        ...route.query,
                        search_type: route.params?.search_type,
                        search_criteria: route.params?.search_criteria,
                        from_search: rqParam.value,
                    },
                });
            });

            marker.set("id", element.id);
            bounder.extend(position);
            return marker;
        });

        placeStore.setters.setMarkersRef(a);

        placeStore.setters.setCurrentMarkersRef(a);

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        markerClusterer.addMarkers(a);
        map.addListener("bounds_changed", async () => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            markerClusterer.clearMarkers();
            const filteredMarkers = a.filter(
                (mark: google.maps.Marker) =>

                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    mark.getVisible() && map.getBounds().contains(mark.getPosition())
            );
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            markerClusterer.addMarkers(filteredMarkers);
        });
    };

    return {
        /**
         * Load all the actions
         */

        // initMap,
        // intiMarkerCluster,
        prepareMarkers,
        // resetAllIcons,
        selectMarker,
        highlightMarker,
        focusOutMarker,
    };
}
